import { useCallback } from 'react';
import { useCurrentUser } from '@qualio/ui-components';
import { DateTime } from 'luxon';
import { isUrlNewFrontend } from './UrlUtils';
import { useFlags } from '../external/LaunchDarklyAdapter';

export enum DateTimeFormat {
  DEFAULT = 'MMMM dd, yyyy hh:mm a',
  TASK = 'dd LLL yyyy hh:mm',
  DUE_DATE = 'LLL d, yyyy',
  APPROVER = "MMM dd, yyyy 'at' hh:mm a",
  DISPLAY_DATE = 'LLL dd, yyyy',
  STANDARD_DATE = 'd LLL yyyy',
  STANDARD_DATETIME = "d LLL yyyy 'at' h:mm a",
}

export const calcTaskDueDate = (createdAt: number, timeLimit: number): Date => {
  const dueDate = DateTime.fromSeconds(createdAt).plus({ days: timeLimit });
  return dueDate.toJSDate();
};

export const getDueValue = (
  referenceDatetime: DateTime,
  dueAtDatetime: DateTime,
  localiseDate: (utcDate: string | Date) => Date,
  isUseLegacyDomainForMtfeEnabled: boolean,
  isClosed?: boolean,
): string => {
  const localisedDueAt = DateTime.fromJSDate(localiseDate(dueAtDatetime.toJSDate()));
  const localisedReferenceDatetime = DateTime.fromJSDate(localiseDate(referenceDatetime.toJSDate()));
  if (isClosed) {
    const closedTaskDueFormat = isUrlNewFrontend(isUseLegacyDomainForMtfeEnabled)
      ? DateTimeFormat.STANDARD_DATE
      : DateTimeFormat.DUE_DATE;
    return localisedDueAt.toFormat(closedTaskDueFormat);
  }

  const diffDays = localisedReferenceDatetime.diff(localisedDueAt, 'days').days;

  switch (true) {
    case diffDays === 0:
      return 'Today';
    case diffDays > 0:
      return localisedDueAt.toRelative({ base: localisedReferenceDatetime }) ?? '';
    case diffDays < 0:
      const dueFormat = isUrlNewFrontend(isUseLegacyDomainForMtfeEnabled)
        ? DateTimeFormat.STANDARD_DATE
        : DateTimeFormat.DUE_DATE;
      return localisedDueAt.toFormat(dueFormat);
    default:
      const defaultFormat = isUrlNewFrontend(isUseLegacyDomainForMtfeEnabled)
        ? DateTimeFormat.STANDARD_DATETIME
        : DateTimeFormat.DEFAULT;
      return localisedDueAt.toFormat(defaultFormat);
  }
};

export const useLocalisedFormatDateTime = (): ((
  timestamp: number | string,
  dateTimeFormat?: string | DateTimeFormat,
  includeTime?: boolean,
) => string) => {
  const { localiseDate, formatDate } = useCurrentUser();
  const isUseLegacyDomainForMtfeEnabled = useFlags('useLegacyDomainForMtfe');
  return useCallback(
    (
      timestamp: number | string,
      dateTimeFormat: string | DateTimeFormat = DateTimeFormat.DEFAULT,
      includeTime = true,
    ) => {
      const dateObj = Number.isFinite(timestamp)
        ? DateTime.fromSeconds(timestamp as number).toJSDate()
        : DateTime.fromISO(timestamp as string).toJSDate();
      if (isUrlNewFrontend(isUseLegacyDomainForMtfeEnabled)) {
        return formatDate(dateObj, includeTime);
      }
      const localisedDate = localiseDate(dateObj);
      return DateTime.fromJSDate(localisedDate).toFormat(dateTimeFormat);
    },
    [isUseLegacyDomainForMtfeEnabled, localiseDate, formatDate],
  );
};

export const convertStringToJsDate = (value: string, fmt = "yyyy-MM-dd'T'HH:mm:ssZ") => {
  return DateTime.fromFormat(value, fmt).toLocal().toJSDate();
};

export const convertEpochToJsDate = (value: number) => {
  return DateTime.fromSeconds(value).toLocal().toJSDate();
};

export const convertUtcStringToDate = (value: string, fmt = "yyyy-MM-dd'T'HH:mm:ss") => {
  if (value) {
    return DateTime.fromFormat(value, fmt, { zone: 'UTC' }).toJSDate();
  }
  return value;
};

export const convertStringToDateTime = (value: string, fmt = "yyyy-MM-dd'T'HH:mm:ssZ") => {
  return DateTime.fromFormat(value, fmt).toLocal();
};

export const convertISOCalendarDateToJSDate = (value: string) => {
  return DateTime.fromISO(value).toJSDate();
};

export const convertISOCalendarDateToDisplayDate = (value: string, isUseLegacyDomainForMtfeEnabled: boolean) => {
  // This is only used for displaying date values that are saved as date string
  // so no localization is needed for this
  const displayDateFormat = isUrlNewFrontend(isUseLegacyDomainForMtfeEnabled)
    ? DateTimeFormat.STANDARD_DATE
    : DateTimeFormat.DISPLAY_DATE;
  return DateTime.fromISO(value).toFormat(displayDateFormat);
};

export const convertToISOCalendarDate = (value: Date) => {
  return DateTime.fromJSDate(value).toISODate();
};

export const convertDateToString = (value: string | Date) => {
  if (value === null || typeof value === 'string') {
    return value;
  }
  return DateTime.fromJSDate(value).toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss");
};
