import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  QModal,
  QStack,
  QText,
  useToastProvider,
  QModalHeader,
  QModalBody,
  QModalActions,
  QButton,
  QCloseButton,
  QAttachment,
} from '@qualio/ui-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { TaskCompleteRequest, UserTask } from '../../types';
import TaskCompleteForm from './components/TaskCompleteForm';
import { buildAttachmentsHTML } from '../../utils/attachmentUtils';
import { extractMessageFromError } from '../../utils/errorUtils';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (payload: TaskCompleteRequest) => Promise<void>;
  task: UserTask;
  isTasksApiCloseLoading: boolean;
};

const TaskCompleteModal: React.FC<Props> = ({ isOpen, setIsOpen, onSave, task, isTasksApiCloseLoading }) => {
  const { showToast } = useToastProvider();
  const [attachments, setAttachments] = useState<Array<QAttachment>>([]);
  const attachmentsUrls = useRef('');

  const formMethods = useForm<TaskCompleteRequest>({
    mode: 'onSubmit',
    resolver: zodResolver(TaskCompleteRequest),
    defaultValues: {},
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  const formHasErrors = !!Object.keys(errors).length;

  const handleClose = () => {
    setIsOpen(false);
    setAttachments([]);
    reset();
  };

  useEffect(() => {
    attachmentsUrls.current = buildAttachmentsHTML(attachments);
  }, [attachmentsUrls, attachments]);

  const onSubmit = useCallback(
    async (data: TaskCompleteRequest) => {
      try {
        const updatedComment = data['comment'] + attachmentsUrls.current;
        const updatedData = { ...data, ...{ comment: updatedComment } };
        await onSave(updatedData);
        setIsOpen(false);
        showToast({
          title: 'Task completed',
          description: `"${task.title}" was marked as completed.`,
          status: 'success',
        });
        setAttachments([]);
        reset();
      } catch (err: any) {
        showToast({
          title: 'Task not completed',
          description: extractMessageFromError(err),
          status: 'error',
        });
      }
    },
    [onSave, showToast, setIsOpen, reset, task, attachmentsUrls],
  );

  const isCompleteDisabled = formHasErrors || isTasksApiCloseLoading;

  return (
    <QModal isOpen={isOpen} onClose={handleClose} size="xl">
      <QModalHeader>
        <QText>Complete task</QText>
        <QCloseButton onClick={handleClose}></QCloseButton>
      </QModalHeader>
      <QModalBody>
        <QStack direction="column" color="gray.700" spacing={4}>
          <TaskCompleteForm
            formMethods={formMethods}
            onSubmit={onSubmit}
            attachments={attachments}
            setAttachments={setAttachments}
          />
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButton onClick={handleClose} variant="outline" isDisabled={isTasksApiCloseLoading}>
          Cancel
        </QButton>
        <QButton
          onClick={() => handleSubmit(onSubmit)()}
          isDisabled={isCompleteDisabled}
          isLoading={isTasksApiCloseLoading}
        >
          Complete
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default TaskCompleteModal;
