import React from 'react';
import { TableMenuItem, useCurrentUser } from '@qualio/ui-components';
import { useArchiveTemplate, useRestoreTemplate, useCreateNewDraftTemplate } from './hooks';
import { EventType } from '../../types';
import { useFlags } from '../../external/LaunchDarklyAdapter';
import * as DisplayStrings from '../../displayStrings';
import { useUserRole } from '../../hooks/useUserRole';

type TemplateRowActionsProps = {
  companyId: number;
  setTemplateForMakeEffective: (templateId: number, lineageId: string) => void;
  setTemplateForChangeOwner: (templateId: number, lineageId: string, ownerName: string, ownerId: string) => void;
  setTemplateForDeleteDraft: (templateId: number, lineageId: string) => void;
};

const TemplateRowActions = ({
  companyId,
  setTemplateForMakeEffective,
  setTemplateForChangeOwner,
  setTemplateForDeleteDraft,
}: TemplateRowActionsProps) => {
  const { mutate: archiveTemplate } = useArchiveTemplate(companyId);
  const { mutate: restoreTemplate } = useRestoreTemplate(companyId);
  const { mutate: createNewDraftTemplate } = useCreateNewDraftTemplate(companyId);
  const qeTemplateChangeManagement = useFlags('qeTemplateChangeManagement');
  const { userId } = useCurrentUser();
  const role = useUserRole();
  const isQualityUser = role === 'quality';

  return (
    <>
      <TableMenuItem
        data-cy="archive-template"
        onClick={(template: EventType) => archiveTemplate(template.id)}
        isHidden={(template: EventType) => template.status !== 'effective' || !template.active}
      >
        Archive
      </TableMenuItem>
      <TableMenuItem
        data-cy="restore-template"
        onClick={(template: EventType) => restoreTemplate(template.id)}
        isHidden={(template: EventType) => template.active}
      >
        Restore
      </TableMenuItem>
      {qeTemplateChangeManagement && (
        <>
          <TableMenuItem
            data-cy="create-template-draft"
            onClick={(template: EventType) => createNewDraftTemplate(template.lineage_id!)}
            isHidden={(template: EventType) =>
              !['effective', 'for_approval', 'approval_declined'].includes(template.status!) || !template.active
            }
          >
            {DisplayStrings.CreateTemplateDraft}
          </TableMenuItem>
          <TableMenuItem
            data-cy="make-template-effective"
            onClick={(template: EventType) => setTemplateForMakeEffective(Number(template.id), template.lineage_id)}
            isDisabled={(template: EventType) => !isQualityUser && template.ownerId !== userId}
            isHidden={(template: EventType) => template.status !== 'draft'}
          >
            {DisplayStrings.MakeEffective}
          </TableMenuItem>
          <TableMenuItem
            data-cy="change-owner"
            onClick={(template: EventType) => {
              if (template.owner) {
                setTemplateForChangeOwner(
                  Number(template.id),
                  template.lineage_id,
                  template.owner,
                  String(template.ownerId),
                );
              }
            }}
            isDisabled={(template: EventType) => !isQualityUser && template.ownerId !== userId}
            isHidden={(template: EventType) => template.status !== 'draft'}
          >
            {DisplayStrings.ChangeTemplateOwner}
          </TableMenuItem>
          <TableMenuItem
            data-cy="delete-draft-template"
            onClick={(template: EventType) => setTemplateForDeleteDraft(Number(template.id), template.lineage_id)}
            isDisabled={(template: EventType) => !isQualityUser && template.ownerId !== userId}
            isHidden={(template: EventType) => template.status !== 'draft'}
          >
            {DisplayStrings.DeleteDraft}
          </TableMenuItem>
        </>
      )}
    </>
  );
};

export default TemplateRowActions;
