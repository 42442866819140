import * as z from 'zod';
import { FormTemplateFieldSchema, NewFormTemplateFieldSchema } from '../../types/formFields/formTemplates';
import { FieldType } from '../../types/formFields/common';

export const DisplayNames = {
  text: 'Single line text',
  rich_text: 'Rich text paragraph',
  date: 'Date',
  dropdown: 'Dropdown',
  boolean: 'Yes / No',
  numeric: 'Numeric',
  attachment: 'Attachment',
  document: 'Document',
  event: 'Event',
  supplier: 'Supplier',
  user: 'User',
  section: 'Section',
  checkbox: 'Checkboxes',
  radio: 'Radio buttons',
  change_request: 'Change request',
  registry: 'Registry',
  design_controls: 'Design controls',
  training_plan: 'Training',
  document_template: 'Templates',
  event_template: 'Event templates',
} as const satisfies Record<FieldType, string>;

export const FormEditorFormSchema = z.object({
  fields: z
    .array(z.union([FormTemplateFieldSchema, NewFormTemplateFieldSchema]))
    .min(1, 'At least one field is required'),
});

export type FormEditorForm = z.infer<typeof FormEditorFormSchema>;

export type AddField = (field: FieldType, resourceSubType?: string) => void;
