import { EventType, EventTypeSchema } from '../types/eventType';
import { medtechApi } from './index';

const eventTypesApi = {
  async getAllEventTypes(company: number, includeArchived = false): Promise<EventType[] | undefined> {
    const options = includeArchived ? { params: { fetch_all: true } } : undefined;
    const res = await medtechApi.get(`/v2/${company}/workflows`, options);

    return EventTypeSchema.array().parse(res.data);
  },
};

export default eventTypesApi;
