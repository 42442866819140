import React, { useMemo } from 'react';
import { QBox, QMenuButton } from '@qualio/ui-components';
import * as DisplayStrings from '../../../../../displayStrings';
import { sortAlphabeticallyByProperty } from '../../../../../utils/arrayUtils';
import { ArrayElement } from '../../../../../utils/typeUtils';
import { FieldTypeSchema } from '../../../../../types/formFields/common';
import { mapRegistryFieldToButton, mapStaticObjectFieldToButton } from '../shared';
import { RegistryCategory } from '../../../../../types/registry';
import { useFlags } from '../../../../../external/LaunchDarklyAdapter';
import { AddField } from '../../../shared';

type QObjectFieldProps = {
  addField: AddField;
  registryCategories: RegistryCategory[];
};

const OldMenuOptions = [
  FieldTypeSchema.Enum.document,
  FieldTypeSchema.Enum.supplier,
  FieldTypeSchema.Enum.event,
  FieldTypeSchema.Enum.user,
  FieldTypeSchema.Enum.change_request,
] as const;

const LT11MenuOptions = [
  ...OldMenuOptions,
  FieldTypeSchema.enum.training_plan,
  FieldTypeSchema.enum.document_template,
  FieldTypeSchema.enum.event_template,
];

const QObjectField = ({ addField, registryCategories }: QObjectFieldProps) => {
  const { dataRegistry: dataRegistryEnabled } = useFlags(['dataRegistry']);

  const { lt11SearchResources } = useFlags(['lt11SearchResources']);

  const MenuOptions = useMemo(() => {
    return lt11SearchResources ? LT11MenuOptions : OldMenuOptions;
  }, [lt11SearchResources]);

  const buttonOptions = useMemo(() => {
    const staticFieldButtons = MenuOptions.map(mapStaticObjectFieldToButton);
    const registryFieldButtons = dataRegistryEnabled ? registryCategories.map(mapRegistryFieldToButton) : [];
    const designControlsFieldButtons = [FieldTypeSchema.Enum.design_controls].map(mapStaticObjectFieldToButton);
    return sortAlphabeticallyByProperty(
      [...staticFieldButtons, ...registryFieldButtons, ...designControlsFieldButtons],
      'label',
    );
  }, [registryCategories, dataRegistryEnabled, MenuOptions]);

  return (
    <QBox data-cy={'add-q-object-field-menu'}>
      <QMenuButton
        buttonLabel={DisplayStrings.ChooseQualioResource}
        itemSize="sm"
        items={buttonOptions}
        onItemClick={(event) => {
          if (
            MenuOptions.includes(event.id as ArrayElement<typeof OldMenuOptions>) ||
            event.id === FieldTypeSchema.Enum.design_controls
          ) {
            addField(event.id as ArrayElement<typeof MenuOptions>);
          } else {
            addField('registry', event.id.split(':')[1]);
          }
        }}
        variant="outline"
        maxHeight={400}
        maxWidth="350px"
      />
    </QBox>
  );
};

export default QObjectField;
