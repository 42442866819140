import { BaseSchema, ServerOnlyOmitKeys } from './common';
import { FieldTypeSchema, MandatorySchema, MultiSchema } from '../common';

/**
 * All types in this file pertain to the template form fields (not the fields on
 * an instance of a form) that are "Q Lookup" input fields, meaning they all search
 * entities in our system for the User to choose from.
 */

export const QLookupFieldSchema = BaseSchema.extend({
  type: FieldTypeSchema.extract([
    'document',
    'event',
    'supplier',
    'user',
    'change_request',
    'registry',
    'design_controls',
    'training_plan',
    'document_template',
    'event_template',
  ]),
  multi: MultiSchema,
  mandatory: MandatorySchema,
});
export const NewQLookupFieldSchema = QLookupFieldSchema.omit(ServerOnlyOmitKeys);
