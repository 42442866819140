import React from 'react';
import { QBadge, QLookup, QText } from '@qualio/ui-components';
import { QObjectFieldType } from '../../../../types/formFields/common';

const eventsView: QLookup.DataView<QLookup.DataProvider.V2Result> = {
  identifiers: {
    header: 'ID',
    render: ({ code }) => <QBadge>{code}</QBadge>,
  },
  name: {
    header: 'Title',
    render: (title) => <QText>{title}</QText>,
  },
  owner: {
    header: 'Owner',
    render: (owner) => <QText> {owner ? owner.fullName : ''}</QText>,
  },
};

const documentsView: QLookup.DataView<QLookup.DataProvider.V2Result> = {
  identifiers: {
    header: 'ID',
    render: ({ code }) => <QBadge>{code}</QBadge>,
  },
  name: {
    header: 'Title',
    render: (title) => <QText>{title}</QText>,
  },
};

const usersView: QLookup.DataView<QLookup.DataProvider.V2Result> = {
  name: {
    header: 'Name',
    render: (name) => <QText>{name}</QText>,
  },
  identifiers: {
    header: 'Email',
    render: ({ code }) => <QBadge>{code}</QBadge>,
  },
};

const suppliersView: QLookup.DataView<QLookup.DataProvider.V2Result> = {
  name: {
    header: 'Name',
    render: (name) => <QText>{name}</QText>,
  },
};

const changeRequestView: QLookup.DataView<QLookup.DataProvider.V2Result> = {
  identifiers: {
    header: 'ID',
    render: ({ code }) => <QBadge>{code}</QBadge>,
  },
  name: {
    header: 'Title',
    render: (title) => <QText>{title}</QText>,
  },
};

const RegistryView: QLookup.DataView<QLookup.DataProvider.V2Result> = {
  identifiers: {
    header: 'ID',
    render: ({ code }) => <QBadge>{code}</QBadge>,
  },
  name: {
    header: 'Title',
    render: (title) => <QText>{title}</QText>,
  },
};

const DesignControlsView: QLookup.DataView<QLookup.DataProvider.V2Result> = {
  identifiers: {
    header: 'ID',
    render: ({ code }) => <QBadge>{code}</QBadge>,
  },
  name: {
    header: 'Title',
    render: (title) => <QText>{title}</QText>,
  },
};

const TrainingView: QLookup.DataView<QLookup.DataProvider.V2Result> = {
  identifiers: {
    header: 'ID',
    render: ({ code }) => <QBadge>{code}</QBadge>,
  },
  name: {
    header: 'Title',
    render: (title) => <QText>{title}</QText>,
  },
};

const DocumentTemplateView: QLookup.DataView<QLookup.DataProvider.V2Result> = {
  identifiers: {
    header: 'ID',
    render: ({ code }) => <QBadge>{code}</QBadge>,
  },
  name: {
    header: 'Title',
    render: (title) => <QText>{title}</QText>,
  },
};

const EventTemplateView: QLookup.DataView<QLookup.DataProvider.V2Result> = {
  identifiers: {
    header: 'ID',
    render: ({ code }) => <QBadge>{code}</QBadge>,
  },
  name: {
    header: 'Title',
    render: (title) => <QText>{title}</QText>,
  },
};

export const ViewMap = {
  event: eventsView,
  document: documentsView,
  user: usersView,
  supplier: suppliersView,
  change_request: changeRequestView,
  registry: RegistryView,
  design_controls: DesignControlsView,
  training_plan: TrainingView,
  document_template: DocumentTemplateView,
  event_template: EventTemplateView,
} as const satisfies Record<QObjectFieldType, QLookup.DataView<unknown>>;
